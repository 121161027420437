import React from 'react'
import { createGlobalStyle } from 'styled-components'
import Reset from './reset'
import config, { device } from './config'

const Base = createGlobalStyle`
  html, body {
    font-size: 18px;
    line-height: 1.33;
    font-family: ${ config.fonts.default }, serif;
    color: var(--color-text, #000);
    transition: color 0.3s;
    height: 100%;
    width: 100%;
    position: relative;

    @media ${ device.sm } {
      font-size: 12px;
    }
  }

  #___gatsby {
    overflow-x: hidden;
    position: relative;
  }

  h1 {
    font-family: 'GT America';
    font-size: 3.88rem;
    line-height: 1;
    margin-bottom: 2.55rem;
  }

  a {
    text-decoration: none;
    color: var(--color-text, #000);
  }

  .my-masonry-grid {
    display: flex;
    margin-left: -5.55rem; /* gutter size offset */
    width: auto;
    z-index: 1;
  }
  .my-masonry-grid_column {
    padding-left: 5.55rem; /* gutter size */
    background-clip: padding-box;
  }

  * { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`

const GlobalStyles = () => (
  <>
    <Reset/>
    <Base/>
  </>
)

export default GlobalStyles
