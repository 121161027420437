import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import config from '../../config/website'

import GTAmericaRegular from '../styles/fonts/GT-America-Standard-Regular.woff2'
import GTSuperDisplayLight from '../styles/fonts/GT-Super-Display-Light.woff2'

const SEO = ({ location, currentProject }) => {
  const {
    site: { buildTime }, datoCmsSite: { globalSeo }, datoCmsSimon: {
      pageTitle: simonPageTitle, video: {
        providerUid: simonUid
      }
    }, datoCmsElena: { pageTitle: elenaPageTitle, video: { providerUid: elenaUid } }
  } = useStaticQuery(graphql`
    query SeoQuery {
      site {
        buildTime(formatString: "YYYY-MM-DD")
      }
      datoCmsSimon {
        pageTitle
        video {
          providerUid
        }
      }
      datoCmsElena {
        pageTitle
        video {
          providerUid
        }
      }
      datoCmsSite {
        globalSeo {
          siteName
          fallbackSeo {
            title
            description
            twitterCard
            image {
              url
            }
          }
        }
      }
    }
  `)

  const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
  const homeURL = `${ config.siteUrl }${ realPrefix }`

  const title = globalSeo.siteName || config.siteTitle
  const description = globalSeo.fallbackSeo.description || config.siteDescription
  const image = isPersonalPage(location) ? null : globalSeo.fallbackSeo.image.url || `${ homeURL }${ config.siteLogo }`
  const { twitterCard } = globalSeo.fallbackSeo

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: homeURL,
    headline: description,
    inLanguage: 'en',
    mainEntityOfPage: homeURL,
    description: description,
    name: title,
    author: {
      '@type': 'Person',
      name: title,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: title,
    },
    copyrightYear: new Date().getYear(),
    creator: {
      '@type': 'Person',
      name: title,
    },
    publisher: {
      '@type': 'Person',
      name: title,
    },
    datePublished: '2019-07-01',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: image,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': homeURL,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  const facebookSeo = [
    { property: 'og:locale', content: config.ogLanguage },
    { property: 'og:url', content: homeURL },
    { property: 'og:site_name', content: title },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:image:alt', content: description },
  ]

  const twitterSeo = [
    { name: 'twitter:card', content: twitterCard },
    { name: 'twitter:url', content: homeURL },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:image:alt', content: description },
  ]

  return (
    <Helmet
      title={generateTitle(location, simonPageTitle, elenaPageTitle, currentProject)}
      htmlAttributes={{ lang: config.siteLanguage }}
      meta={[
        { name: title, content: description },
        { name: 'description', content: description },
        { name: 'keywords', content: config.keywords },
        { name: 'image', content: image },
        { name: 'msapplication-TileColor', content: config.backgroundColor },
        { name: 'msapplication-config', content: 'browserconfig.xml' },
        ...facebookSeo,
        ...twitterSeo
      ]}
      link={!isPersonalPage(location) ? [
        { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'shortcut icon', href: '/favicon.ico' },
      ] : []}
    >
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      {/* revisit this eventually */}
      <link rel="preload" as="font" type="font/woff2" href={`${ homeURL }${ GTAmericaRegular }`} crossorigin="anonymus"/>
      <link rel="preload" as="font" type="font/woff2" href={`${ homeURL }${ GTSuperDisplayLight }`} crossorigin="anonymus"/>
      <link href="//webfonts3.radimpesko.com/RP-W-e3ec07bf-222f-4073-b162-e264c9925a66" rel="stylesheet"></link>
      {/* {isPersonalPage(location) &&
        <base href="https://www.botanicalagency.com"/>
      } */}
      {isPersonalPage(location) &&
        <link rel="shortcut icon" href={`${ homeURL }/blankfavicon.ico`}/>
      }
      {isPersonalPage(location) &&
        <script>
          {`eval("fetch('https://onelineplayer.glitch.me/vimeo?url=https://vimeo.com/${ getUid(location, simonUid, elenaUid) }').then(response => response.json()).then(data => {document.querySelector('video').setAttribute('src', data.video['720p']);document.querySelector('video').play();})")`}
        </script>
      }
    </Helmet>
  )
}

function getUid (location, simonUid, elenaUid) {
  if (/simondedreuille/ig.test(location)) {
    return simonUid
  } else if (/elenaseegers/ig.test(location)) {
    return elenaUid
  }
}

function generateTitle (location, simonPageTitle, elenaPageTitle, currentProject) {
  if (/simondedreuille/ig.test(location)) {
    return simonPageTitle
  } else if (/elenaseegers/ig.test(location)) {
    return elenaPageTitle
  }

  if (/commissions/ig.test(location)) {
    return 'Botanical Agency - Commissions'
  }

  if (/privacy-policy/ig.test(location)) {
    return 'Botanical Agency - Privacy Policy'
  }

  if (currentProject) {
    return `Botanical Agency - ${ currentProject }`
  }

  return 'Botanical Agency'
}

function isPersonalPage (location) {
  return /simondedreuille|elenaseegers/ig.test(location)
}

export default memo(SEO)
