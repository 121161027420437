import React from 'react'
import PropTypes from 'prop-types'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
// import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/seo'
import Navigation from '../components/navigation'
import GlobalStyles from '../styles/globalStyles'
import BackgroundWrapper from '../components/BackgroundWrapper'
import Cookie from '../components/Cookie'

const gridTheme = {
  flexboxgrid: {
    // Defaults
    gridSize: 8, // columns
    gutterWidth: 5.55, // rem
    outerMargin: 3.33, // rem
    mediaQuery: 'only screen',
    // container: {
    //   sm: 46, // rem
    //   md: 61, // rem
    //   lg: 76 // rem
    // },
    breakpoints: {
      // xs: 0, // em
      // sm: 42, // em
      // md: 64, // em
      // lg: 75 // em
      xs: 0,
      sm: 50,
      md: 60,
      lg: 73
    }
  }
}

const Layout = ({ children, location, pageContext: { name } }) => {
  const blackBackground = location.pathname.indexOf('commissions') >= 0

  return (
    <>
      <GlobalStyles/>
      <SEO location={location.pathname} currentProject={name}/>
      {!isPersonalPage(location.pathname) && children &&
        <BackgroundWrapper blackBackground={blackBackground}/>
      }
      <CookiesProvider>
        <ThemeProvider theme={gridTheme}>
          <>
            {!isPersonalPage(location.pathname) && <Navigation currentPathname={location.pathname} blackBackground={blackBackground}/>}
            {children}
            <Cookie/>
          </>
        </ThemeProvider>
      </CookiesProvider>
    </>
  )
}

function isPersonalPage (pathname) {
  return /simondedreuille|elenaseegers/ig.test(pathname)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
