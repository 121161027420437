// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-commissions-js": () => import("./../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-elenaseegers-js": () => import("./../src/pages/elenaseegers.js" /* webpackChunkName: "component---src-pages-elenaseegers-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-simondedreuille-js": () => import("./../src/pages/simondedreuille.js" /* webpackChunkName: "component---src-pages-simondedreuille-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

