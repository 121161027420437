import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import config, { device } from '../styles/config'

import ShuffleText from '../utils/shuffleText'

const NavigationItem = styled(Link)`
  font-family: 'GT America';
  color: var(--color-text);
  position: relative;
  transition: color 1s;

  @media ${ device.lg } {
    /* flex: 0; */
  }

  @media ${ device.md } {
    font-size: 0.9rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  @media ${ device.sm } {
    font-size: 1.16rem;
  }
`

const Container = styled.nav`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${ config.pageWidth };
  margin: 0 auto;
  padding-left: 0.6rem;
  padding-right: 0.6rem;

  ${ NavigationItem } {
    ${ props => props.blackBackground && css`
      color: ${ config.colors.darkThemeText };
      transition: color 0.2s;
    ` }
  }

  @media ${ device.sm } {
    height: 167px;
    padding-left: 0;
    padding-right: 0;
  }

  ${ Row }  {
    width: 100%;
    align-items: flex-start;

    @media ${ device.sm } {
      ${ Col } {
        padding-left: ${ config.pagePaddingMobile };
        padding-right: ${ config.pagePaddingMobile };

        &:first-child {
          padding-right: 0;
        }

        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
`

const Logo = styled(NavigationItem)`
  font-family: 'Lyno Jean';
  font-weight: 300;
  font-size: 2.77rem;
  line-height: 2.77rem;
  height: 2.77rem;
  white-space: nowrap;

  @media ${ device.md } {
    font-size: 2rem;
    line-height: 0.95;
  }
`

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${ device.sm } {
    flex-direction: column;
  }
`

const LinkGroup = styled.div`
  width: 42%;
  display: flex;
  flex-direction: column;

  @media ${ device.sm } {
    width: 100%;
  }
`

class Navigation extends Component {
  constructor (props) {
    super(props)

    this.logoRef = React.createRef()
    this.timeout = null
    this.shuffleToLong = null
    this.shuffleToShort = null
  }

  componentDidMount () {
    if (!this.shuffleToShort && !this.shuffleToLong) {
      this.shuffleToShort = new ShuffleText(this.logoRef.current, { alternateEnding: 'BA' })
      this.shuffleToLong = new ShuffleText(this.logoRef.current, { alternateEnding: 'Botanical Agency' })
    }

    this.timeout = setTimeout(() => {
      this.timeout = null
      this.shuffleToShort.start()
    }, 2000)
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  startShuffle = () => {
    !this.timeout && this.shuffleToLong && this.shuffleToLong.start()
  }

  endShuffle = () => {
    !this.timeout && this.shuffleToShort && this.shuffleToShort.start()
  }

  render () {
    const { className, blackBackground } = this.props

    return (
      <Container className={className} blackBackground={blackBackground}>
        <Row>
          <Col
            xs={5}
            sm={4}
            onMouseEnter={this.startShuffle}
            onMouseLeave={this.endShuffle}
          >
            <Logo ref={this.logoRef} to="/">
            Botanical Agency
            </Logo>
          </Col>
          <Col xs={3} sm={4}>
            <LinksContainer>
              <NavigationItem to="/">
                Selected Works
              </NavigationItem>
              <NavigationItem to="/contact">
                  About
                </NavigationItem>
                <NavigationItem to="/contact">
                  Contact
                </NavigationItem>
              {/* <LinkGroup> */}
                
                
                {/* <NavigationItem to="/commissions">
                    Commissions
                </NavigationItem> */}
              {/* </LinkGroup> */}
              {/* <LinkGroup> */}
                
              {/* </LinkGroup> */}
              {/* <LinkGroup> */}
                {/* <NavigationItem as="a" target="blank" href="https://elenaseegers.com">
                  Elena Seegers
                </NavigationItem>
                <NavigationItem as="a" target="blank" href="https://simondedreuille.com">
                  Simon&nbsp;de Dreuille
                </NavigationItem> */}
                
              {/* </LinkGroup> */}
            </LinksContainer>
          </Col>
        </Row>
      </Container>
    )
  }
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  currentPathname: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: '',
}

export default Navigation
