let ShuffleText = /** @class */ (function () {
  function ShuffleText (element, options) {
    this.sourceRandomCharacter = 'abcdefghijklmnopqrstuvwxyz'
    this.emptyCharacter = '&#8205;'
    this.duration = 300
    this._isRunning = false
    this._originalStr = ''
    this._originalLength = 0
    this._timeCurrent = 0
    this._timeStart = 0
    this._randomIndex = []
    this._element = null
    this._requestAnimationFrameId = 0
    this._element = element
    this._alternateEnding = options.alternateEnding
    this.setText(element.innerHTML)
  }

  ShuffleText.prototype.setText = function (text) {
    this._originalStr = this._alternateEnding || text
    this._originalLength = text.length
  }
  Object.defineProperty(ShuffleText.prototype, 'isRunning', {
    get: function () {
      return this.isRunning
    },
    enumerable: true,
    configurable: true
  })
  ShuffleText.prototype.start = function () {
    let _this = this
    this.stop()
    this._randomIndex = []
    let str = ''
    for (let i = 0; i < this._originalLength; i++) {
      let rate = i / this._originalLength
      this._randomIndex[i] = Math.random() * (1 - rate) + rate
      str += this.emptyCharacter
    }
    this._timeStart = new Date().getTime()
    this._isRunning = true
    this._requestAnimationFrameId = requestAnimationFrame(function () {
      _this._onInterval()
    })
    this._element.innerHTML = str
  }
  ShuffleText.prototype.stop = function () {
    this._isRunning = false
    cancelAnimationFrame(this._requestAnimationFrameId)
  }
  ShuffleText.prototype.dispose = function () {
    cancelAnimationFrame(this._requestAnimationFrameId)
    this.sourceRandomCharacter = null
    this.emptyCharacter = null
    this._isRunning = false
    this.duration = 0
    this._originalStr = null
    this._originalLength = 0
    this._timeCurrent = 0
    this._timeStart = 0
    this._randomIndex = null
    this._element = null
    this._requestAnimationFrameId = 0
  }
  ShuffleText.prototype._onInterval = function () {
    let _this = this
    this._timeCurrent = new Date().getTime() - this._timeStart
    let percent = this._timeCurrent / this.duration
    let str = ''
    for (let i = 0; i < this._originalLength; i++) {
      if (percent >= this._randomIndex[i]) {
        str += this._originalStr.charAt(i)
      } else if (percent < this._randomIndex[i] / 3) {
        str += this.emptyCharacter
      } else {
        str += this.sourceRandomCharacter.charAt(Math.floor(Math.random() * (this.sourceRandomCharacter.length)))
      }
    }
    if (percent > 1) {
      str = this._originalStr
      this._isRunning = false
    }
    this._element.innerHTML = str
    if (this._isRunning === true) {
      this._requestAnimationFrameId = requestAnimationFrame(function () {
        _this._onInterval()
      })
    }
  }
  return ShuffleText
}())

export default ShuffleText
