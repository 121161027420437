import React, { useEffect, useState, memo } from 'react'
// import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import throttle from 'lodash.throttle'
import styled, { createGlobalStyle, keyframes } from 'styled-components'
import isMobile from '../utils/isMobile'
import { device } from '../styles/config'
import lightOrDark from '../utils/lightOrDark'

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Base = createGlobalStyle`
  :root {
    --color-text: ${ props => props.invertedTextColor ? '#fff' : '#000' };
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${ props => props.colour };
  transition: background-color 1s;
  transform: translate3d(0,0,0);
  overflow: hidden;
  z-index: -1;
  opacity: ${ props => props.visible ? 1 : 0 };
  visibility: ${ props => props.visible ? 'visible' : 'hidden' };

  @media ${ device.sm } {
    background-color: initial;
    animation: ${ appear } 0.6s 0.6s;
    animation-fill-mode: forwards; 
    opacity: 0;
    background: ${ props => `linear-gradient(to bottom, ${ props.mobileColourTop }, ${ props.mobileColourBottom })` };
  }
`

const BlackBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  z-index: -1;
  transition: opacity 1s, visibility 1s;
  transform: translate3d(0,0,0);
  opacity: ${ props => props.visible ? 1 : 0 };
  visibility: ${ props => props.visible ? 'visible' : 'hidden' };
`

const BackgroundWrapper = ({ blackBackground }) => {
  const { datoCmsHomepage: { backgroundColours } } = useStaticQuery(graphql`
  query colorQuery {
    datoCmsHomepage {
      backgroundColours {
        colour {
          hex
        }
      }
    }
  }
  `)
  const [colour, setColour] = useState(backgroundColours[0].colour.hex)

  useEffect(() => {
    const throttledMouseMove = throttle(onMouseMove, 400)
    if (typeof window !== 'undefined' && !isMobile()) {
      window.addEventListener('mousemove', throttledMouseMove)
    }

    function onMouseMove (event) {
      updateBackground(event.clientX / window.innerWidth)
    }

    function updateBackground (position) {
      let index

      if (position < 0.33) {
        index = 0
      } else if (position > 0.33 && position < 0.66) {
        index = 1
      } else {
        index = 2
      }

      setColour(backgroundColours[index].colour.hex)
    }

    return () => {
      if (typeof window !== 'undefined' && !isMobile()) {
        window.removeEventListener('mousemove', throttledMouseMove)
      }
    }
  }, [])

  return (
    <>
      <Base invertedTextColor={lightOrDark(colour) === 'dark' || isMobile}/>
      <Container
        visible={!blackBackground}
        colour={colour}
        mobileColourTop={backgroundColours[0].colour.hex}
        mobileColourBottom={backgroundColours[2].colour.hex}
      />
      <BlackBackgroundContainer visible={blackBackground}/>
    </>
  )
}

export default memo(BackgroundWrapper)
