module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"
  siteTitle: 'Botanical Agency', // Navigation and Site Title
  siteTitleAlt: 'Botanical Agency', // Alternative Site title for SEO
  siteTitleShort: 'Botanical Agency', // short_name for manifest
  siteHeadline: 'Botanical Agency', // Headline for schema.org JSONLD
  siteUrl: 'https://www.botanicalagency.com', // Domain of your site. No trailing slash!
  // siteUrl: 'http://localhost:8000', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Botanical agency is a duo of spacial practicioners based in Paris and New York. Elena Seegers and Simon de Dreuille are trained in architecture, Botany and History of the environemnt.',
  author: 'insel.berlin.development', // Author for schema.org JSONLD
  keyword: 'architecture, botany, environment, ',
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@cara', // Twitter Username
  ogSiteName: 'Botanical Agency', // Facebook Site Name
  ogLanguage: 'en_EN', // Facebook Language
  // googleAnalyticsID: 'UA-47519312-5',

  // Manifest and Progress color
  themeColor: '#6C82FF',
  backgroundColor: '#FFFFFF',
}
