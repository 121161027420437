const config = {
  fonts: {
    default: 'GT America',
  },
  colors: {
    primary: '#000',
    accent: '#C29EA8',
    blue: '#F3F3FF',
    orange: '#FEF3EF',
    darkThemeText: 'rgb(125,125,125)'
  },
  pageWidth: '1200px',
  pagePadding: '3.33rem',
  pagePaddingMobile: '1.6rem',
  breakpoints: {
    xs: 0,
    sm: 50,
    md: 60,
    lg: 73
  }
}

const device = {
  xs: `(max-width: ${ config.breakpoints.xs }rem)`,
  sm: `(max-width: ${ config.breakpoints.sm }rem)`,
  md: `(max-width: ${ config.breakpoints.md }rem)`,
  lg: `(max-width: ${ config.breakpoints.lg }rem)`,
}

export { device }
export default config
