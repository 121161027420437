import React from 'react'
import styled, { css } from 'styled-components'
import { Row } from 'react-styled-flexboxgrid'
import config, { device } from '../styles/config'
import { useCookies } from 'react-cookie'

import ExpandSvg from '../images/expand-light.svg'

const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 65px;
  background: black;
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const Text = styled.div`
  color: white;
  letter-spacing: 0.3px;
  font-size: 0.77rem;
  font-family: 'GT America', sans-serif;

  padding-right: ${ config.pagePadding };

  @media ${ device.sm } {
    padding-right: ${ config.pagePaddingMobile };
  }
`

const Button = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${ ExpandSvg });
  background-size: 12px 10px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`

function Cookie () {
  const [cookies, setCookie] = useCookies(['consent'])

  if (typeof window === 'undefined') {
    return null
  }

  if (cookies.consent) {
    return null
  }

  return (
    <Container>
      <Text>
        By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.
      </Text>
      <Button onClick={() => {
        setCookie('consent', true, {
          maxAge: 31536000
        })
      }}/>
    </Container>
  )
}

export default Cookie
